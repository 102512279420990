import * as filestack from 'filestack-js';

export interface FilestackHandle {
  handle: string;
  url: string;
  originalFile?: object | File;
}

const client = filestack.init(import.meta.env.VITE_FILESTACK_API_KEY);

function createFilestackPicker(options: filestack.PickerOptions): filestack.PickerInstance {
  return client.picker({
    container: '#file-upload',
    ...options,
  });
}

export function openFilestackPicker(options: filestack.PickerOptions): Promise<void> {
  return createFilestackPicker(options).open();
}

export function openFilestackCropper(urls: string[], options: filestack.PickerOptions): Promise<void> {
  return createFilestackPicker(options).crop(urls);
}
